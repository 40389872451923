<template>
  <div class="show-hide-password">
    <button @click="toggle" type="button">
      <i :class="{
        'icon-show-password': value === 'password',
        'icon-hide-password': value !== 'password'
      }"></i>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: 'password'
    }
  },
  methods: {
    toggle () {
      this.$emit('input', this.value === 'password' ? 'text' : 'password')
    }
  }
}
</script>

<style lang="scss" scoped>
.show-hide-password {
  button {
    background: transparent;
    padding: 0;
    border: none;
    font-size: 24px;
    line-height: 24px;
    height: 24px;
    width: 24px;
    color: rgba(33, 38, 49, 0.5);
  }
}
</style>
