<template>
  <div class="view-register auth">
    <nav class="container">
      <div class="d-flex justify-content-between align-items-center mb-4 pt-4">
        <img src="@/assets/logo.png" />
        <div>
          <router-link class="btn btn-outline-light" :to="{ name: 'login' }">
            Entrar
          </router-link>
        </div>
      </div>
    </nav>

    <div class="container">
      <div class="row">
        <div class="col-md-7 col-lg-5 mx-auto">
          <form class="card my-4" @submit.prevent="submit">
            <div class="card-body">
              <div class="step">Passo 1 de 3</div>

              <h3 class="text-center">Cadastre-se</h3>
              <p class="text-center mb-4 pb-1">
                Faça o seu cadastro para começar a usar.
              </p>

              <div v-if="errors.non_field_errors && errors.non_field_errors.length" class="non-field-error my-4">
                {{ errors.non_field_errors[0] }}
              </div>

              <form-group
                required
                v-model="name"
                id="name"
                type="text"
                placeholder="Seu nome"
                :errors="errors.name"
              />

              <form-group
                required
                v-model="document"
                id="document"
                type="tel"
                placeholder="CPF"
                mask="###.###.###-##"
                :errors="errors.document"
              />

              <form-group
                required
                v-model="phone"
                id="phone"
                type="text"
                placeholder="Número de celular"
                :errors="errors.phone"
                is-phone
                @country-changed="onCountryChanged"
                @validate="validatePhone"
              />

              <form-group
                required
                v-model="email"
                id="email"
                type="email"
                placeholder="Seu e-mail"
                :errors="errors.email"
              />

              <div class="position-relative mb-4">
                <form-group
                  required
                  class="mb-0"
                  v-model="password"
                  id="password"
                  :type="passwordInputType"
                  placeholder="Escolha uma senha"
                  :errors="errors.password"
                />
                <show-hide-password v-model="passwordInputType" />
              </div>

              <div class="position-relative mb-4">
                <form-group
                  required
                  class="mb-0"
                  v-model="passwordConf"
                  id="passwordConf"
                  :type="passwordConfInputType"
                  placeholder="Repita a senha"
                  :errors="errors.passwordConf"
                />
                <show-hide-password v-model="passwordConfInputType" />
              </div>

              <b-form-checkbox
                id="terms"
                v-model="terms"
                name="terms"
                :value="true"
              >
                <small>
                  Declaro que li e concordo com os
                  <a href="https://ocorpoexplica.com.br/termos-de-uso/" target="_blank" class="font-weight-bold text-light">
                    Termos de uso
                  </a>
                </small>
              </b-form-checkbox>

              <div class="text-center pt-3">
                <button
                  type="submit"
                  class="btn btn-success btn-lg btn-block"
                  :disabled="!terms || loading"
                >
                  <loading :show="loading">Cadastrar</loading>
                </button>
              </div>

               <!-- <img class="img-fluid my-4" src="../assets/images/or.svg" />

              <facebook-login app-id="766174930801321" @login="facebookLogin">
                <template v-slot:logo>
                  <i class="icon-facebook"></i>
                </template>
                <template v-slot:login>
                  Entrar com o Facebook
                </template>
              </facebook-login> -->
            </div>
          </form>
        </div>
      </div>
    </div>

    <b-modal
      v-model="termDialog"
      hide-footer
      size="xl"
    >
      <term-text />
    </b-modal>
  </div>
</template>

<script>
import ShowHidePassword from '@/components/ShowHidePassword'
// import FacebookLogin from 'vue-facebook-login-component'
import TermText from '../components/terms/Text'

export default {
  name: 'register',
  components: {
    ShowHidePassword,
    // FacebookLogin,
    TermText
  },
  data () {
    return {
      phoneIsValid: false,
      countryCode: '',
      termDialog: false,
      passwordInputType: 'password',
      passwordConfInputType: 'password',
      terms: false,
      name: '',
      document: '',
      phone: '',
      email: '',
      password: '',
      passwordConf: '',
      loading: false,
      errors: {
        name: [],
        document: [],
        phone: [],
        email: [],
        password: [],
        passwordConf: []
      }
    }
  },
  methods: {
    validatePhone ({ isValid }) {
      this.phoneIsValid = isValid
    },
    onCountryChanged (country) {
      this.countryCode = country.dialCode
    },
    postLogin () {
      this.$store.dispatch('postLogin').then(() => {
        this.$router.replace({ name: 'home' })
      })
    },
    facebookLogin (response) {
      if (response && response.authResponse) {
        this.$store
          .dispatch('user/facebookLogin', response.authResponse)
          .then(this.postLogin)
      }
    },
    submit () {
      if (this.passwordConf !== this.password) {
        this.errors.passwordConf = ['Senhas digitadas são diferentes']
        return
      }
      if (!this.phoneIsValid) {
        this.errors.phone = ['Insira um telefone válido']
        return
      }

      this.$message.hide()

      this.loading = true

      this.$store
        .dispatch('user/register', {
          name: this.name,
          document: this.document.replace(/\D/g, ''),
          phone: this.countryCode + this.phone.replace(/\D/g, ''),
          email: this.email,
          password: this.password
        })
        .then(this.postLogin)
        .catch(error => {
          this.errors = error.response.data
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.view-register {
  ::v-deep .show-hide-password {
    position: absolute;
    right: 12px;
    top: 12px;
  }
}
</style>
